import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Modal, Typography, Button } from '@mui/material'

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate()

  // Redirect users to /list-chats after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/list-chats')
    }, 3000)
    return () => { clearTimeout(timer) }
  }, [navigate])

  return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Modal
                open={true}
                onClose={() => {}}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box style={{
                  width: '300px',
                  padding: '20px',
                  backgroundColor: 'white',
                  borderRadius: '8px'
                }}>
                    <Typography id="simple-modal-title" variant="h6" component="h2">
                        Thank You!
                    </Typography>
                    <Typography id="simple-modal-description" variant="body2" color="textSecondary" gutterBottom>
                        Thanks for going premium. Redirecting you in a few seconds...
                    </Typography>
                    <Button variant="contained" color="primary" onClick={() => { navigate('/list-chats') }}>
                        Go to Chats
                    </Button>
                </Box>
            </Modal>
        </div>
  )
}

export default PaymentSuccess
