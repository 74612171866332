/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { useState } from 'react'
import { TEAMS_EXPORTER_API_BASE_URL } from '../../config'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAuth } from '../../contexts/authContext'

interface ExportButtonProps {
  chatId: string
  chatTopic: string
  startDate: Date | null
  endDate: Date | null
}

const ExportButton: React.FC<ExportButtonProps> = ({ chatId, chatTopic, startDate, endDate }) => {
  const { authenticatedRequest, updateNumberOfChatsExported } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleExportClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    setLoading(true)
    event.stopPropagation()
    const response = await authenticatedRequest({
      method: 'post',
      url: `${TEAMS_EXPORTER_API_BASE_URL}/export-chat/${chatId}`,
      data: {
        chatTopic,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString()
      }
    })
    setLoading(false)
    if (response?.data?.url) {
      window.open(response.data.url, '_blank')

      const userMeResponse = await authenticatedRequest({
        method: 'get',
        url: `${TEAMS_EXPORTER_API_BASE_URL}/user-me`
      })

      console.log('User Me Response:', userMeResponse)
      const { data: numberOfChatsExported } = userMeResponse
      updateNumberOfChatsExported(numberOfChatsExported.numberOfChatsExported)
    }
  }

  return (
    <LoadingButton loading={loading} variant="outlined" color="secondary" onClick={handleExportClick}>
      Export as PDF
    </LoadingButton>
  )
}

export default ExportButton
